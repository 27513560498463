/** @jsx jsx */
import { graphql } from "gatsby";
import { Box, Container, Heading, jsx } from "theme-ui";
import ContentWithImage from "../../components/ContentWithImage";
import HexagonList from "../../components/HexagonList";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

const RoofInspections = ({ data }) => {
  return (
    <Layout
      contactBackground={data.ContactBackground.childImageSharp.gatsbyImageData}
      headerBannerText="roof Inspection"
      headerBgImage={data.HeroImage.childImageSharp.gatsbyImageData}
      headerFancyBanner={false}
      headerLinkButtonEnabled={true}
    >
      <Seo title="Roof Inspections" />

      <Box as="article" sx={{ my: [8, null, 11] }}>
        <Container>
          <ContentWithImage
            imageAlt="Man wearing a mask and writing on a clipboard."
            imageURL={data.MaintenanceServices.childImageSharp.gatsbyImageData}
            titleText="Roof Inspections in the Greater Phoenix Area"
          >
            <p>
              Legacy Repair provides inspections for roofs throughout the year.
              For a reasonable flat fee, we’ll send out a trained technician to
              perform a point-by-point review of your roof and ensure there are
              no potential issues and hazards. This inspection should help you
              prepare for any serious weather and get an idea of your roof life.
              An inspection costs less than 1% of the total of a new roof.
            </p>

            <Heading as="h2" variant="styles.h3" sx={{ mt: 6 }}>
              Here Are Some Things We Look For:
            </Heading>

            <ol>
              <li>Age of the roof</li>
              <li>Points of blockage or damming</li>
              <li>Penetrations and wear of sealants </li>
              <li>Damage from hail, rain and wind</li>
              <li>Exposure of fasteners or other components</li>
              <li>
                Issues with brackets, braces and other mounting equipment for
                solar and AC units
              </li>
            </ol>

            <p>
              These are just a few of the items you need to consider when having
              a roof inspection performed. Inspection steps and timing would
              vary depending on roof type and size. Many homes in Phoenix have
              multiple roof types, so the process could be longer.
            </p>

            <p>
              Call Legacy today to get your roof inspected and save money in the
              long run!
            </p>
          </ContentWithImage>
        </Container>
      </Box>

      <HexagonList
        bannerText="roof repair made easy."
        linkText="Get Started"
        linkURL="/contact"
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    HeroImage: file(relativePath: { eq: "roof-inspection-hero.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    RoofTopsIcon: file(relativePath: { eq: "roof-tops.svg" }) {
      id
      publicURL
    }
    ContactBackground: file(relativePath: { eq: "footer-bg.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    MaintenanceServices: file(
      relativePath: { eq: "man-holding-clipboard.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default RoofInspections;

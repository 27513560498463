/** @jsx jsx */
import { jsx, Box, Flex, Heading } from "theme-ui";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import LinkButton from "./LinkButton";
import BackgroundDots from "../images/icons/background-dots-1.svg";

const ContentWithImage = ({
  children,
  className,
  imageAlt,
  imageURL,
  linkArr,
  linkArrTitle,
  linksEnabled,
  titleText,
}) => {
  const image = getImage(imageURL);

  return (
    <Flex
      className={className}
      sx={{
        alignItems: "flex-start",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          flex: ["0 0 100%", null, "0 0 45%"],
        }}
      >
        <div sx={{ mb: 5 }}>
          <Heading as="h1" variant="styles.fancyUnderline">
            {titleText}
          </Heading>
        </div>

        <Box>{children}</Box>

        {linksEnabled ? (
          <nav sx={{ mb: [6, null, 0] }}>
            <Heading
              as="h3"
              variant="styles.h3"
              sx={{
                mb: [4, null, 6],
                textAlign: ["center", null, "left"],
              }}
            >
              {linkArrTitle}
            </Heading>

            <ul
              sx={{
                columnGap: [0, null, "20px"],
                display: ["flex", null, "grid"],
                flexWrap: "wrap",
                gridTemplateColumns: [null, null, "repeat(3, 30%)"],
                justifyContent: "space-between",
                listStyleType: "none",
                m: 0,
                p: 0,
                rowGap: [0, null, "20px"],
                li: {
                  flex: ["0 0 47%", null, "unset"],
                  textAlign: "center",
                  "&:nth-of-type(n + 3)": {
                    mt: [4, null, 0],
                  },
                  "&:last-of-type:nth-of-type(odd)": {
                    flex: ["0 0 100%", null, "unset"],
                    a: {
                      px: [5, null, 0],
                      width: ["47%", null, "100%"],
                    },
                  },
                },
              }}
            >
              {linkArr.map((linkData, index) => (
                <li key={index}>
                  <LinkButton
                    to={linkData.linkURL}
                    sx={{
                      px: 0,
                      whiteSpace: "pre-wrap",
                      width: "100%",
                    }}
                  >
                    {linkData.linkText}
                  </LinkButton>
                </li>
              ))}
            </ul>
          </nav>
        ) : null}
      </Box>

      <Box
        sx={{
          flex: ["0 0 100%", null, "0 0 45%"],
          position: "relative",
        }}
      >
        <GatsbyImage
          image={image}
          alt={imageAlt}
          sx={{
            height: "92%",
            ml: "10%",
            mt: "8%",
            position: "relative",
            verticalAlign: "middle",
            width: "90%",
            zIndex: 4,
          }}
        />

        <BackgroundDots
          sx={{
            height: [280, null, 422],
            left: 0,
            position: "absolute",
            top: 0,
            transform: "rotate(-90deg)",
            verticalAlign: "middle",
            width: [280, null, 422],
            zIndex: 3,
          }}
        />
      </Box>
    </Flex>
  );
};

ContentWithImage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  imageAlt: PropTypes.string,
  linkArr: PropTypes.array,
  linkArrTitle: PropTypes.string,
  linksEnabled: PropTypes.bool,
  titleText: PropTypes.string,
};

ContentWithImage.defaultProps = {
  linksEnabled: false,
  linkArr: [],
};

export default ContentWithImage;

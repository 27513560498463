/** @jsx jsx */
import PropTypes from "prop-types";
import { Box, Container, Heading, jsx } from "theme-ui";
import LinkButton from "../components/LinkButton";
import SkewedText from "../components/SkewedText";
import SplitHexagon from "../images/split-hexagon.svg";

const DEFAULT_CONTENT = [
  {
    name: "REQUEST SERVICE",
    content: (
      <p>Let us know what your roof is, what you need and how we can help!</p>
    ),
  },
  {
    name: "Service Call",
    content: <p>We’ll follow up with a service call and a plan for a visit.</p>,
  },
  {
    name: "Repair",
    content: (
      <p>
        We’ll arrive on-site ready to address the situation upon arrival -
        ensuring no run around and that your property is protected and secure.
      </p>
    ),
  },
  {
    name: "Payment and Follow-Up",
    content: (
      <p>
        Once the job is complete, we’ll send details and a follow up for
        payment!
      </p>
    ),
  },
];

const HexagonList = ({
  bannerText,
  className,
  linkText,
  linkURL,
  listContent = DEFAULT_CONTENT,
  ...props
}) => {
  return (
    <Container
      as="section"
      sx={{
        my: [8, null, 11],
      }}
    >
      <Box
        {...props}
        className={className}
        sx={{
          backgroundColor: "darkBlue",
          boxShadow: "0px 0px 35px 10px rgba(0, 0, 0, 0.25)",
          mt: ["17px", "28px", "30px"], // fixes space made by skewed text
          pb: [4, null, 11],
          position: "relative",
          pt: [10, null, 14],
        }}
      >
        <ol
          sx={{
            color: "textWhite",
            columnGap: [1, null, 5],
            counterReset: "li-counter",
            display: "grid",
            gridTemplateColumns: ["1fr", null, "1fr 1fr"],
            listStyleType: "none",
            m: 0,
            px: [3, null, 10],
            rowGap: [6, null, 6],
            li: {
              alignItems: ["center", null, "start"],
              counterIncrement: "li-counter",
              display: "flex",
              flexDirection: ["column", null, "row"],
              justifyContent: ["center", null, "flex-start"],
              "span:first-of-type": {
                flex: "auto",
                pl: [0, null, 4],
                textAlign: ["center", null, "left"],
              },
              "&::before": {
                alignItems: "center",
                backgroundImage: `url(${SplitHexagon})`,
                backgroundSize: "cover",
                color: "textWhite",
                content: "counter(li-counter)",
                display: "flex",
                flex: ["0 0 48px", null, "0 0 82px"],
                fontSize: [5, null, 6],
                fontWeight: "bold",
                height: [48, null, 71],
                justifyContent: "center",
                mb: [3, null, 0],
                width: [55, null, 82],
              },
            },
          }}
        >
          {listContent.map((item, index) => (
            <li key={item.name}>
              <span>
                <Heading
                  as="h3"
                  variant="styles.h3"
                  sx={{
                    color: "textWhite",
                    textTransform: "uppercase",
                  }}
                >
                  {item.name}
                </Heading>
                <div>{item.content}</div>
              </span>
            </li>
          ))}
        </ol>

        <div sx={{ textAlign: "center", mt: [8, null, 10] }}>
          <LinkButton to={linkURL} variant="darkBlue">
            {linkText}
          </LinkButton>
        </div>

        <SkewedText
          as="h1"
          text={bannerText}
          sx={{
            left: -20,
            position: "absolute",
            top: 0,
          }}
        />
      </Box>
    </Container>
  );
};

HexagonList.propTypes = {
  bannerText: PropTypes.string,
  className: PropTypes.string,
  linkText: PropTypes.string,
  linkURL: PropTypes.string,
};

HexagonList.defaultProps = {
  linkText: "Get Started",
};

export default HexagonList;
